import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src2648711630/src/storefront/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src2648711630/src/storefront/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src2648711630/src/storefront/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src2648711630/src/storefront/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/codebuild/output/src2648711630/src/storefront/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src2648711630/src/storefront/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src2648711630/src/storefront/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/codebuild/output/src2648711630/src/storefront/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src2648711630/src/storefront/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src2648711630/src/storefront/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_LcKgStRyi6 from "/codebuild/output/src2648711630/src/storefront/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_ghbUAjaD3n from "/codebuild/output/src2648711630/src/storefront/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_client_OVoKJro5pc from "/codebuild/output/src2648711630/src/storefront/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/codebuild/output/src2648711630/src/storefront/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/codebuild/output/src2648711630/src/storefront/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_AUP22rrBAc from "/codebuild/output/src2648711630/src/storefront/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import amplifyConfig_DYdzvAghzJ from "/codebuild/output/src2648711630/src/storefront/plugins/amplifyConfig.ts";
import analytics_client_mZYnNRQBH8 from "/codebuild/output/src2648711630/src/storefront/plugins/analytics.client.js";
import authHub_eAq4HlsZtM from "/codebuild/output/src2648711630/src/storefront/plugins/authHub.js";
import chunk_reload_client_BG2S3BHLQY from "/codebuild/output/src2648711630/src/storefront/plugins/chunk-reload.client.ts";
import error_handler_VFH3VvK7yG from "/codebuild/output/src2648711630/src/storefront/plugins/error-handler.js";
import fontawesome_klhsrycjcK from "/codebuild/output/src2648711630/src/storefront/plugins/fontawesome.js";
import gtm_client_KEcT5dOoKs from "/codebuild/output/src2648711630/src/storefront/plugins/gtm.client.js";
import localStorageWatcher_client_sWT67OHphO from "/codebuild/output/src2648711630/src/storefront/plugins/localStorageWatcher.client.js";
import piniaSubscribe_QwMIqHRsem from "/codebuild/output/src2648711630/src/storefront/plugins/piniaSubscribe.js";
import router_fix_eTZilvszUA from "/codebuild/output/src2648711630/src/storefront/plugins/router.fix.ts";
import sw_deregister_client_izDKFs6MOX from "/codebuild/output/src2648711630/src/storefront/plugins/sw-deregister.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_LcKgStRyi6,
  plugin_ghbUAjaD3n,
  plugin_client_OVoKJro5pc,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_AUP22rrBAc,
  amplifyConfig_DYdzvAghzJ,
  analytics_client_mZYnNRQBH8,
  authHub_eAq4HlsZtM,
  chunk_reload_client_BG2S3BHLQY,
  error_handler_VFH3VvK7yG,
  fontawesome_klhsrycjcK,
  gtm_client_KEcT5dOoKs,
  localStorageWatcher_client_sWT67OHphO,
  piniaSubscribe_QwMIqHRsem,
  router_fix_eTZilvszUA,
  sw_deregister_client_izDKFs6MOX
]